import { styled } from "@mui/system";
import { RatazimaTheme } from "../../../Theme";
import VerticalSpacer from "../../../common/VerticalSpacer";
import ImageListItem from "@mui/material/ImageListItem";
import { useEffect, useState } from "react";
import { getAvailableTattoos } from "../../../firebase/firestore";
import CardMedia from "@mui/material/CardMedia/CardMedia";
import Button from "@mui/material/Button";
import useWindowDimensions from "../../../Theme/WindowDimensions";
import { SiteContent } from "../../../contexts/SiteContent";

export default function DisponiveisHome({ setImagesLoaded }: any) {
  const [tattoos, setTattoos] = useState<Array<any>>([]);
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    width && setIsMobile(width < RatazimaTheme.breakpoints.tablet_number);
  }, [width]);

  useEffect(() => {
    getAvailableTattoos().then((_tattoos: any) => {
      setImagesLoaded((prev: any) => ({
        imgsToLoad: prev.imgsToLoad + _tattoos.length,
        imgsLoaded: prev.imgsLoaded,
      }));
      setTattoos(_tattoos.filter((tattoo : any) => !tattoo.tags.includes("coração")));
      // console.log(_tattoos.filter((tattoo : any) => !tattoo.tags.includes("coração")));
      // console.log(_tattoos);

      _tattoos.forEach((tattoo: any) => {
        const img = new Image();
        img.src = tattoo.urls[0];
        img.onload = () => {
          setImagesLoaded((prev: any) => ({
            imgsToLoad: prev.imgsToLoad,
            imgsLoaded: prev.imgsLoaded + 1,
          }));
        };
      });
      // console.log("Testing....", _tattoos);
    });
  }, []);

  return (
    <DisponiveisHomeContainer>
      <div className="disponiveis-home-content-wraper">
        <div className="disponiveis-home-texto">
          <div className="disponiveis-home-title-wraper">
            <h1 className="disponiveis-home-title">
              {SiteContent.disponiveis.title.sub}
              <br /> <span>{SiteContent.disponiveis.title.main}</span>
            </h1>
          </div>
          <p className="disponiveis-home-text">
            {SiteContent.disponiveis.description}
          </p>
        </div>

        <VerticalSpacer height="4rem" />

        {/* <ImageList
          variant="quilted"
          className="image-list"
          sx={{ width: "100%" }}
          cols={isMobile ? 2 : 3}
          gap={isMobile ? 6 : 14}
          rowHeight={"auto"}
        > */}
        <div className="myImageList">
          {tattoos.slice(0, isMobile ? 4 : 6).map((item) => (
            <ImageListItem key={item.id}>
              <CardMedia
                style={{ height: "100%", paddingTop: "0" }}
                component="img"
                image={item.urls[0]}
                title="tattoos"
                alt="Tattoos"
              />
              <HoverCard
                url={item.urls[1]}
                position={item.position}
                zoom={item.zoom}
              >
                <div>
                  <p>{item.title}</p>
                  <p>{item.size}</p>
                  <h1>R$ {item.price}</h1>
                  <h2>Sugestão de aplicação: {item.place}</h2>
                  <Button
                    className="mais-info-button"
                    size={isMobile ? "large" : "small"}
                    fullWidth
                    variant="outlined"
                    href={`disponiveis/id/${item.id}`}
                    color="secondary"
                    sx={{ marginTop: "10px" }}
                  >
                    Mais informações
                  </Button>
                </div>
              </HoverCard>
            </ImageListItem>
          ))}
        </div>
        {/* </ImageList> */}
        <Button
          className="ver-todos-button"
          sx={{ marginTop: "1rem" }}
          fullWidth
          variant="contained"
          color="secondary"
          href="/disponiveis/"
        >
          Ver todos
        </Button>
      </div>
    </DisponiveisHomeContainer>
  );
}

const HoverCard = styled("div")<{
  url: string;
  position: string;
  zoom: string;
}>`
  opacity: 0;
  position: absolute;
  background: url(${(props) => props.url});
  background-size: 100%;
  background-position: ${(props) => props.position};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;

  div {
    color: black;
    line-height: 1.2;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0)
    );
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 1rem;
  }

  p {
    pointer-events: none;
    line-height: 1.5;
    font-size: 1.5rem;
  }

  h1 {
    pointer-events: none;
    color: ${(props) => props.theme.palette.grey["800"]};
    font-weight: 800;
  }
  h2 {
    pointer-events: none;
    font-size: 1rem;
    color: ${(props) => props.theme.palette.grey["800"]};
  }
  .mais-info-button {
    transition: pointer-events 0.3s ease-in-out;
    pointer-events: none;
  }

  &:hover {
    opacity: 1;
    background-size: ${(props) => props.zoom};
    .mais-info-button {
      animation-name: example;
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }
  }

  @keyframes example {
    from {
      pointer-events: none;
    }
    to {
      pointer-events: all;
    }
  }

  @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
    p {
      font-size: 1rem;
    }
    h1 {
      font-size: 1.2rem;
    }
    h2 {
      display: none;
    }
    div {
      padding: 0.4rem;
      .mais-info-button {
        padding: 0.4rem 0.5rem;
        font-size: 0.8rem;
      }
    }

    &:hover {
      .mais-info-button {
        /* pointer-events: all; */
      }
    }
  }
`;

const DisponiveisHomeContainer = styled("section")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: auto;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;

  /* border: 1px solid white; */

  .disponiveis-home-content-wraper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${RatazimaTheme.dimensions.maxColumnWidth};
    height: 100%;

    .myImageList {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 8px;
      width: 100%;
    }

    .ver-todos-button{
      width: 100%;
    }

    --bio-home-ratio: 50%;

    .disponiveis-home-texto {
      color: ${(props) => props.theme.palette.primary.main};
      text-align: center;

      .disponiveis-home-title-wraper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .disponiveis-home-title {
          width: min-content;
          white-space: nowrap;
          position: relative;
          font-size: 36px;
          font-weight: 400;
          margin: 0 0 2rem 0;

          span {
            font-size: 60px;
            font-weight: 800;
          }

          &:after {
            position: absolute;
            content: "";
            height: 3px;
            left: 0;
            right: 0;
            bottom: -8px;
            /* width: 100px; */
            background: ${(props) => props.theme.palette.secondary.main};
          }
        }
      }
      .disponiveis-home-text {
        margin-top: 2rem;
        padding: 0 3rem;
        font-weight: 300;
      }
    }
  }
  @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
    width: 100%;
    .disponiveis-home-content-wraper {
      width: 100%;

      .image-list {
        width: 80%;
        min-height: max-content;
      }

      .myImageList {
        width: 95%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;
      }

      .ver-todos-button {
        width: 95%;
      }

      .disponiveis-home-texto {
        display: flex;
        flex-direction: column;
        align-items: center;

        .disponiveis-home-title-wraper {
          .disponiveis-home-title {
            font-size: calc(0.5 * ${RatazimaTheme.fontSizes.mainTitles});
            span {
              font-size: calc(1 * ${RatazimaTheme.fontSizes.mainTitles});
            }
          }
        }
        .disponiveis-home-text {
          width: 85%;
          padding: 0;
        }
      }
    }
  }
`;
