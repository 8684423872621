import { styled } from "@mui/system";
import { RatazimaTheme } from "../../../Theme";
import VerticalSpacer from "../../../common/VerticalSpacer";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useEffect, useState } from "react";
import {
  getGalleryImages,
} from "../../../firebase/firestore";
import CardMedia from "@mui/material/CardMedia/CardMedia";
import Button from "@mui/material/Button";
import useWindowDimensions from "../../../Theme/WindowDimensions";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CloseIcon from "@mui/icons-material/Close";
import { SiteContent } from "../../../contexts/SiteContent";

export default function GaleriaHome({ setImagesLoaded }: any) {
  const [tattoos, setTattoos] = useState<Array<any>>([]);
  const { width} = useWindowDimensions();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [expandImageUrl, setExpandImageUrl] = useState<{
    url: string;
    index: number;
  }>({ url: "", index: -1 });
  const [hiddenTag, setHiddenTag] = useState("");

  useEffect(() => {
    width && setIsMobile(width < RatazimaTheme.breakpoints.tablet_number);
  }, [width]);

  useEffect(() => {
    getGalleryImages().then((_tattoos) => {
      setImagesLoaded((prev: any) => ({
        imgsToLoad: prev.imgsToLoad + _tattoos.length,
        imgsLoaded: prev.imgsLoaded,
      }));
      setTattoos(_tattoos);

      _tattoos.forEach((tattoo) => {
        const img = new Image();
        img.src = tattoo.urls[0];
        img.onload = () => {
          setImagesLoaded((prev: any) => ({
            imgsToLoad: prev.imgsToLoad,
            imgsLoaded: prev.imgsLoaded + 1,
          }));
        };
      });
      // console.log("Testing....", _tattoos);
    });
  }, []);

  return (
    <GaleriaHomeContainer>
      {expandImageUrl.url && (
        <Fade in={true}>
          <div
            id="expand-image-bg"
            style={{
              zIndex: 10000,
              background: "rgba(0,0,0,0.8)",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={(event: any) => {
              if (event.target.id === "expand-image-bg") {
                setExpandImageUrl({ url: "", index: -1 });
              }
              // setExpandImageUrl("");
            }}
          >
            <Grow in={true}>
              <div className="expanded-image-wraper">
                <img
                  src={expandImageUrl.url}
                  alt=""
                  className="expanded-image"
                />
                {expandImageUrl.index < (isMobile ? 4 : 6) - 1 && (
                  <Button
                    color="primary"
                    variant="contained"
                    className="right-arrow"
                    onClick={() =>
                      setExpandImageUrl({
                        url: tattoos[expandImageUrl.index + 1].urls[0],
                        index: expandImageUrl.index + 1,
                      })
                    }
                  >
                    <ArrowCircleRightIcon fontSize="medium" />
                  </Button>
                )}
                {expandImageUrl.index > 0 && (
                  <Button
                    variant="contained"
                    className="left-arrow"
                    onClick={() =>
                      setExpandImageUrl({
                        url: tattoos[expandImageUrl.index - 1].urls[0],
                        index: expandImageUrl.index - 1,
                      })
                    }
                  >
                    <ArrowCircleLeftIcon fontSize="medium" />
                  </Button>
                )}
                <Button
                  variant="contained"
                  className="close-button"
                  onClick={() =>
                    setExpandImageUrl({
                      url: "",
                      index: -1,
                    })
                  }
                >
                  <CloseIcon fontSize="small" />
                </Button>
              </div>
            </Grow>
          </div>
        </Fade>
      )}

      <div className="galeria-home-content-wraper">
        <div className="galeria-home-texto">
          <div className="galeria-home-title-wraper">
            <h1 className="galeria-home-title">
              <span>{SiteContent.galeria.title.main}</span> <br />
              {SiteContent.galeria.title.sub}
            </h1>
          </div>
          <p className="galeria-home-text">{SiteContent.galeria.description}</p>
        </div>

        <VerticalSpacer height="4rem" />
        <ImageList
          variant="standard"
          sx={{ width: "100%", height: "auto" }}
          cols={isMobile ? 2 : 3}
          gap={isMobile ? 6 : 8}
        >
          {tattoos.slice(0, isMobile ? 4 : 6).map((item, index) => (
            <ImageListItem key={item.id}>
              <CardMedia
                style={{ height: "auto", paddingTop: "0" }}
                component="img"
                image={item.urls[0]}
                title="tattoos"
                alt="Tattoos"
                onClick={() => {
                  setExpandImageUrl({ url: item.urls[0], index: index });
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
        <Button
          sx={{ marginTop: "1rem" }}
          fullWidth
          variant="contained"
          color="secondary"
          href="/Galeria"
        >
          Ver todos
        </Button>
      </div>
    </GaleriaHomeContainer>
  );
}

const GaleriaHomeContainer = styled("section")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;

  .expanded-image-wraper {
    position: relative;
    width: min-content;
    height: min-content;
    /* border: 1px solid red; */

    .right-arrow {
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
    .left-arrow {
      position: absolute;
      bottom: 15px;
      left: 15px;
    }

    .close-button {
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 5px;
      min-width: 0;
    }
    .left-arrow,
    .right-arrow {
      padding: 5px;
      color: rgba(0, 0, 0, 0.5);
      min-width: 0px;
    }
    .expanded-image {
      height: 90vh;
      position: relative;

      @media only screen and (max-width: 768px) {
        height: auto;
        width: 90vw;
      }
    }
  }

  /* border: 1px solid white; */

  .galeria-home-content-wraper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${RatazimaTheme.dimensions.maxColumnWidth};
    height: 100%;

    --bio-home-ratio: 50%;

    .galeria-home-texto {
      color: ${(props) => props.theme.palette.primary.main};
      text-align: center;

      .galeria-home-title-wraper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .galeria-home-title {
          width: min-content;
          white-space: nowrap;
          position: relative;
          font-size: calc(${RatazimaTheme.fontSizes.mainTitles}*.5);
          font-weight: 400;
          margin: 0 0 2rem 0;

          span {
            font-size: calc(${RatazimaTheme.fontSizes.mainTitles}*1);
            font-weight: 800;
          }

          &:after {
            position: absolute;
            content: "";
            height: 3px;
            left: 0;
            right: 0;
            bottom: -8px;
            /* width: 100px; */
            background: ${(props) => props.theme.palette.secondary.main};
          }
        }
      }
      .galeria-home-text {
        margin-top: 2rem;
        padding: 0 3rem;
        font-weight: 300;
      }
    }

    @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
      width: 95%;
    }
  }
`;
