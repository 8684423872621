import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";

interface iProps {
  name: string;
}

export default function Input({ name, ...rest }: any) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div style={{  width: "100%", display: "flex"}}>
      <CustomInput
        size="small"
        inputRef={inputRef}
        defaultValue={defaultValue}
        {...rest}
        error={error}
        helperText={error}
        variant="standard"
        
      />
    </div>
  );
}

const CustomInput = styled(TextField)`
  width: auto;
  flex-grow: 1;
  margin: 5px;

  input {
    padding: 4px 4px;
    scrollbar-width: none;

  }

  input:-webkit-scrollbar {
    width: 3px;
  }
`;
