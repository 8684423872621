import folhaCoracoes from './folha-love-is-a-losing-game_.webp';
import heroBgImage from './BG-hero-folha-coracoes_.webp';
import folha2018 from './folhas/folha_brokenheart_2018_.webp';
import folha2019 from './folhas/folha_brokenheart_2019_.webp';
import folha2021 from './folhas/folha_brokenheart_2021_.webp';

import brokenheart01 from "./caroussel/02_broken-hearts-2022_.webp";
import brokenheart02 from "./caroussel/03_broken-hearts-2022_.webp";
import brokenheart03 from "./caroussel/04_broken-hearts-2022_.webp";
import brokenheart04 from "./caroussel/05_broken-hearts-2022_.webp";
import brokenheart05 from "./caroussel/06_broken-hearts-2022_.webp";
import brokenheart06 from "./caroussel/07_broken-hearts-2022_.webp";
import brokenheart07 from "./caroussel/08_broken-hearts-2022_.webp";
import brokenheart08 from "./caroussel/09_broken-hearts-2022_.webp";
import brokenheart09 from "./caroussel/10_broken-hearts-2022_.webp";
import brokenheart10 from "./caroussel/11_broken-hearts-2022_.webp";
import brokenheart11 from "./caroussel/12_broken-hearts-2022_.webp";
import brokenheart12 from "./caroussel/13_broken-hearts-2022_.webp";
import brokenheart13 from "./caroussel/14_broken-hearts-2022_.webp";
import brokenheart14 from "./caroussel/15_broken-hearts-2022_.webp";
import brokenheart15 from "./caroussel/16_broken-hearts-2022_.webp";
import brokenheart16 from "./caroussel/17_broken-hearts-2022_.webp";
import brokenheart17 from "./caroussel/18_broken-hearts-2022_.webp";
import brokenheart18 from "./caroussel/19_broken-hearts-2022_.webp";
import brokenheart19 from "./caroussel/20_broken-hearts-2022_.webp";
import brokenheart20 from "./caroussel/21_broken-hearts-2022_.webp";



const brokenHearts = [
  brokenheart01,
  brokenheart02,
  brokenheart03,
  brokenheart04,
  brokenheart05,
  brokenheart06,
  brokenheart07,
  brokenheart08,
  brokenheart09,
  brokenheart10,
  brokenheart11,
  brokenheart12,
  brokenheart13,
  brokenheart14,
  brokenheart15,
  brokenheart16,
  brokenheart17,
  brokenheart18,
  brokenheart19,
  brokenheart20,
];

export default brokenHearts;
export { folhaCoracoes, heroBgImage, folha2018, folha2019, folha2021 };
