import React, { useEffect, useRef } from "react";
import { styled } from "@mui/system";
import imgs from "../../images/hearts/";
// import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
// import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
// import Button from "@mui/material/Button";
// import IconButton from '@mui/material/IconButton';

const itemWidth = 150;
const itemMargin = 0;
let oldScrollLeft = 0;
let cooldown = 2000;
let holdScroll = true;
let scrollSpeed = 2;

window.onclick = () => {
  cooldown = 200;
};
window.onscroll = () => {
  if (cooldown === 0) holdScroll = false;
  cooldown = 200;
};
function setElementScroll(
  element: HTMLDivElement,
  value: number,
  behavior: string = "auto"
) {
  element.style.setProperty("scroll-behavior", behavior);
  element.scrollLeft = value;
  element.style.setProperty("scroll-behavior", "smooth");
}
function scrollElementBy(element: HTMLDivElement, value: number) {
  setElementScroll(element, element.scrollLeft + value, "auto");
}

export default function CardsCaroussel() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [imgList, setimgList] = React.useState<string[]>([...imgs, ...imgs]);

  // const [scrollPosition, setScrollPosition] = React.useState(0);

  useEffect(() => {
    let intervalId: any;
    document.addEventListener("mouseup", () => {
      scrollSpeed = 2;
    });
    document.addEventListener("touchend", () => {
      scrollSpeed = 2;
    });

    if (containerRef.current) {
      const container = containerRef.current;
      // container.addEventListener("mousedown", () => {
      //   cooldown = 1000;
      //   holdScroll = true;
      // });
      // container.addEventListener("touchstart", () => {
      //   cooldown = 1000;
      //   holdScroll = true;
      // });
      // container.addEventListener("mouseup", () => {
      //   holdScroll = false;
      // });
      // container.addEventListener("touchend", () => {
      //   holdScroll = false;
      // });
      intervalId = setInterval(() => {
        cooldown <= 0 && scrollElementBy(container, scrollSpeed);
        cooldown > 0 && !holdScroll && (cooldown -= 25);
      }, 25);

      container.addEventListener("scroll", () => {
        const itemsWidth = imgList.length * (itemWidth + itemMargin * 2);
        const containerWidth = container.getBoundingClientRect().width;
        const deltaScroll = container.scrollLeft - oldScrollLeft;
        let direction = deltaScroll > 0 ? 1 : -1;
        // console.log(deltaScroll, direction, containerWidth, itemsWidth);
        oldScrollLeft = container.scrollLeft;

        if (Math.abs(deltaScroll) < containerWidth / 2) {
          if (
            container.scrollLeft > itemsWidth / 2 + containerWidth / 2 &&
            direction > 0
          ) {
            container.style.setProperty("scroll-behavior", "auto");
            container.scrollLeft -= itemsWidth / 2 + 1;
            container.style.setProperty("scroll-behavior", "smooth");
            // setElementScroll(container, container.scrollLeft - ((imgList.length/2) * (itemWidth + itemMargin*2)) + 0);
          } else if (
            container.scrollLeft <
              (imgList.length / 2) * (itemWidth + itemMargin * 2) -
                containerWidth &&
            direction < 0
          ) {
            // console.log("returning to right");
            container.style.setProperty("scroll-behavior", "auto");
            container.scrollLeft += itemsWidth / 2;
            container.style.setProperty("scroll-behavior", "smooth");
          }
        }

        if (container.scrollLeft === 0) {
          setElementScroll(container, itemsWidth / 2);
        }
        if (container.scrollLeft === itemsWidth - containerWidth) {
          //setElementScroll(container, itemsWidth / 2 - containerWidth);
        }
      });
      setElementScroll(
        container,
        (imgList.length * itemWidth +
          imgList.length * (itemMargin * 2) -
          container.getBoundingClientRect().width) /
          2
      );

      return () => {
        container.removeEventListener("scroll", () => {});
        clearInterval(intervalId);
      };
    }
  }, []);

  return (
    <CarousselSection>
      <CarousselContainer ref={containerRef}>
        {imgList.map((image, index) => (
          <CarousselItem key={index} img_url={image} img_size={100}>
            {/* <img src={image} alt="" /> */}
            <div className="image-container"></div>
          </CarousselItem>
        ))}
      </CarousselContainer>
      {/* <CarousselButtons>
        <IconButton>
          <ArrowCircleLeftIcon fontSize="large" onMouseDown={()=> scrollSpeed = -15} onTouchStart={()=> scrollSpeed = -15}/>
        </IconButton>
        <IconButton>
          <ArrowCircleRightIcon fontSize="large" onMouseDown={()=> scrollSpeed = 15} onTouchStart={()=> scrollSpeed = 15}/>
        </IconButton>
      </CarousselButtons> */}
      {/* <h1>{scrollPosition}</h1> */}
    </CarousselSection>
  );
}

const CarousselButtons = styled("div")`
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CarousselContainer = styled("div")`
  position: relative;
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.values.md + "px"};
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  pointer-events: none;

  -webkit-mask-image: linear-gradient(
    90deg,
    transparent,
    black 2% 98%,
    transparent
  );
  mask-image: linear-gradient(90deg, transparent, black 20% 80%, transparent);
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface iCarousselItemProps {
  img_url: string;
  img_size: number;
}

const CarousselItem = styled("div")<iCarousselItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  overflow: hidden;
  flex: none;
  border-radius: 1rem;
  margin: 0 ${itemMargin}px;
  color: white;

  .image-container {
    background: url(${(props) => props.img_url});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: ${itemWidth}px;
    height: 160px;
  }

  img {
    width: ${itemWidth}px;
    height: 500px;
    object-fit: contain;
  }
`;

const CarousselSection = styled("section")`
  display: flex;
  width: 100%;
  min-height: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin: 0rem 0;
`;
