import React from "react";
import { styled } from "@mui/system";
import { heroBgImage } from "../../../images/hearts/";
import CardsCaroussel from "../../../common/Caroussel";
// import { Link } from "@mui/material";
import Button from "@mui/material/Button";


export default function HeartsHome() {

  return (
    <PageContainer>
      <Hero>
        <div className="hero-textbox">
          <h1>
            COLEÇÃO <br /> <span>BROKEN HEARTS</span>
          </h1>
          <Button
          className="ver-todos-button"
          sx={{ marginTop: "1rem" }}
          
          variant="contained"
          color="secondary"
          href="/broken-hearts/"
        >
          Ver todos
        </Button>
          
        </div>
      </Hero>
      {/* <CardsCaroussel /> */}
    </PageContainer>
  );
}


const Hero = styled("section")`
  background-image: url(${heroBgImage});
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 70vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .hero-textbox {
    width: 95%;
    max-width: 700px;
    height: min-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 1.5rem;
    padding: 2rem 0;

    h1 {
      font-size: 2rem;
      font-weight: 700;

      @media only screen and (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 1.5rem;
      }
      span {
        font-size: 4.5rem;
        font-weight: 900;
        text-shadow: 3px 3px 0px ${(props) => props.theme.palette.secondary.light};
      }
    }
    .link-ig {
      color: white;
    }
  }
`;

const PageContainer = styled("section")`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* overflow-y: scroll; */
`;
