import React from "react";
import { styled } from "@mui/system";
import { folhaCoracoes, heroBgImage, folha2018, folha2019, folha2021 } from "../../../images/hearts/";
import CardsCaroussel from "../../../common/Caroussel";
import ContatoHome from "../../Home/Contato";
import VerticalSpacer from "../../../common/VerticalSpacer";
import { Link } from "@mui/material";

export default function Hearts() {
  const [openURL, setOpen] = React.useState("");

  const handleClickOpen = (url: string) => {
    setOpen(url);
  };
  const handleClose = () => {
    setOpen("");
  };
  return (
    <PageContainer>
      {openURL && (
        <ExpandedImage
          onClick={(e: any) => {
            console.log(e.target.tagName);
            e.target.tagName !== "IMG" && handleClose();
          }}
        >
          <img src={openURL} alt="Banner" />
        </ExpandedImage>
      )}
      <Hero>
        <div className="hero-textbox">
          <h1>
            Lançamento da nova coleção <br /> <span>BROKEN HEARTS</span>
          </h1>
          <Link target="_blank" href="https://www.instagram.com/ratazima" className="link-ig">
            @ratazima
          </Link>
        </div>
      </Hero>
      <CardsCaroussel />
      <Content>
        <div style={{ flexDirection: "column" }} className="wraper">
          <h1 style={{ lineHeight: 1, textAlign: "center" }}>Lançamento da quarta coleção de corações!</h1>
          <br />
          <p>
            <b>“LOVE IS A LOSING GAME”</b> traz 20 corações novinhos para serem tatuados. Os desenhos têm cerca de 6 centímetros e se encaixam bem em qualquer
            lugar do corpo. <br />
            <br /> A nova coleção também foi transformada em print para você decorar sua casa. A impressão foi feita em papel Rives Design Bright White no
            tamanho A3. Lembrando que os prints das coleções <b>“EVERYBODY HAS A BROKEN HEART”</b> e <b>“LOVE IS NOT ENOUGH”</b> também estão disponíveis e
            comprando mais de um o valor é reduzido! <br />
            <br /> Novos adesivos foram adicionados ao pack de adesivos de corações. Agora o envelope vem com 18 unidades sortidas! <br />
            <br />E além de lançamento tem promoção! É só preencher todos os campos no fim da página e enviar uma mensagem, assim você garante 10% de desconto
            no pack de adesivo, prints e tatuagem. O desconto é aplicável para tatuar qualquer um dos os corações, incluindo os antigos das folhas{" "}
            <b>“EVERYBODY HAS A BROKEN HEART”</b>, <b>“LOVE IS NOT ENOUGH”</b> e <b>“LOVE WILL FIND YOU”</b>. <br />
            <br />
          </p>
        </div>
        <div className="wraper">
          <div className="image">
            <img src={folhaCoracoes} alt="Hearts" onClick={() => handleClickOpen(folhaCoracoes)} />
          </div>
          <div className="text">
            <div className="tabela-precos">
              <div>
                <div style={{ fontWeight: 800 }}>Valores:</div>
                <div style={{ fontWeight: 800 }}>Valor com desconto (10%)</div>
              </div>
              <div>
                <div className="valores">Tatuagem 1 coração R$350</div>
                <div className="valores-com-desconto">R$ 315,00</div>
              </div>
              <div>
                <div className="valores">Pack adesivo 18 unidades R$25</div>
                <div className="valores-com-desconto">R$ 22,50</div>
              </div>
              <div>
                <div className="valores">1 print R$ 50</div>
                <div className="valores-com-desconto">R$ 45,00</div>
              </div>
              <div>
                <div className="valores">2 prints R$90</div>
                <div className="valores-com-desconto">R$ 81,00</div>
              </div>
              <div>
                <div className="valores">3 prints R$120</div>
                <div className="valores-com-desconto">R$ 108,00</div>
              </div>
            </div>
            <p style={{ lineHeight: 1 }}>
              <span>
                <br />
                Vendas para Curitiba: frete ou local de retirada a combinar. Vendas para outras cidades: valor do frete e embalagem de envio a combinar.
              </span>
              <br />
            </p>
            <div className="more-images">
              <img src={folha2018} alt="Hearts" onClick={() => handleClickOpen(folha2018)} />
              <img src={folha2019} alt="Hearts" onClick={() => handleClickOpen(folha2019)} />
              <img src={folha2021} alt="Hearts" onClick={() => handleClickOpen(folha2021)} />
            </div>
          </div>
        </div>
        <p className="aviso">
          Desenhos não-exclusivos, tatuados repetidamente. Tamanho aproximado de 6cm. É possível alterar ou retirar a frase. É possível adicionar cores.
        </p>
      </Content>
      <ContatoHome
        title="GARANTA SEU"
        subtitle="DESCONTO!"
        description="Preencha todos os campos e garanta 10% de desconto na sua tattoo de coração, 10% de desconto no valor dos prints e 10% de desconto no pack de adesivos!"
      />
      <VerticalSpacer height="5rem" />

      <div style={{ fontSize: "1.2rem", color: "white", marginBottom: 45, padding: "0 2rem", textAlign: "center" }}>
        <p>Em caso de dúvidas ou problemas, você pode entrar em contato diretamente pelo email tazima.rafael@gmail.com ou whatsapp (43) 99683-4944.</p>
      </div>
    </PageContainer>
  );
}

const ExpandedImage = styled("div")`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  img {
    width: min-content;
    min-width: 30%;
    max-width: 95%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const Content = styled("section")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
  color: white;

  .wraper {
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
      flex-direction: column;
      width: 95%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: ${(props) => props.theme.breakpoints.values.md + "px"};
    line-height: 1.5;
  }

  .tabela-precos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
      display: flex;
      justify-content: space-between;
    }
  }

  .aviso {
    text-align: center;
    color: white;
    max-width: 50%;
    font-size: 0.75rem;
    margin-top: 1rem;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 0 2rem;

    @media only screen and (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
      padding: 0 1rem;
    }

    p {
      @media only screen and (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        text-align: center;
      }
      font-size: 1.2rem;
      span {
        text-align: center;
        font-size: 0.8rem;
        line-height: 1;
      }
    }
    .more-images {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0.5rem;
      width: 100%;
      height: 250px;
      flex: 0;
      margin-top: 2rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .image {
    width: 35%;
    min-width: 35%;
    height: auto;

    @media only screen and (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
      width: 100%;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
`;

const Hero = styled("section")`
  background-image: url(${heroBgImage});
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 70vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .hero-textbox {
    width: 95%;
    max-width: 700px;
    height: min-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 1.5rem;
    padding: 2rem 0;

    h1 {
      font-size: 2rem;
      font-weight: 700;

      @media only screen and (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 1.5rem;
      }
      span {
        font-size: 4.5rem;
        font-weight: 900;
        text-shadow: 3px 3px 0px ${(props) => props.theme.palette.secondary.light};
      }
    }
    .link-ig {
      color: white;
    }
  }
`;

const PageContainer = styled("main")`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
`;
