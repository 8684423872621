import { styled } from "@mui/system";

export const HomeContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* min-height: 100vh; */
    
    
    .comming-soon {
        p{
            text-align: center;
            color: whitesmoke;
            font-size: 1.5rem;
            font-weight: 500;
        }
    }
    
    .icons {
        display: flex;
        align-items: center;
        justify-content: center;

        
        .icon-social-media {
            color: whitesmoke;
            font-size: 3rem;
        }
    }
    
`;
