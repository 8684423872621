import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { RatazimaTheme } from "../../../Theme";
import ImageUpload from "../ImageUpload";
import { Form } from "@unform/web";
import Input from "../../../common/Form/input";
import { useRef } from "react";
import { FormHandles, SubmitHandler, Scope } from "@unform/core";
import * as Yup from "yup";
import {
  addAvailableTattoo,
  getAvailableTattoos,
} from "../../../firebase/firestore";
import { uploadFiles } from "../../../firebase/storage";
import TattooCard from "./TattooCard";
import CircularProgress from "@mui/material/CircularProgress";

export default function AvailableTattoos() {
  const [tattoos, setTattoos] = useState<Array<any>>([]);
  const [expand, setExpand] = useState<boolean>(false);

  const loadData = () => {
    getAvailableTattoos().then((_tattoos) => {
      setTattoos(_tattoos);
      console.log("Testing....", _tattoos);
    });
  };
  return (
    <>
      <Card
        sx={{
          padding: "1rem 2rem",
          display: "flex",
          flexDirection: "column",
          width: RatazimaTheme.dimensions.maxColumnWidth,
          margin: "2rem",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ margin: ".5rem 0" }}>Desenhos Disponíveis</h2>
          <Button
            size="small"
            onClick={() => {
              setExpand(!expand);
            }}
          >
            Mostrar
          </Button>
        </div>

        {expand && (
          <div style={{marginTop: 15}}>
            <AddNew loadData={loadData} />
            <AvailableList tattoos={tattoos} loadData={loadData} />
          </div>
        )}
      </Card>
    </>
  );
}

const AddNew = ({ loadData }: any) => {
  const [filesToUpload, setFilesToUpload] = useState<Array<any>>([]);
  const formRef = useRef<FormHandles>(null);

  const handleSubmit: SubmitHandler = async (data: any, { reset }) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        title: Yup.string().required("Por favor, informe o titulo"),
        description: Yup.string().required(
          "Por favor, informe uma breve descriçao"
        ),
        price: Yup.string().required("Por favor, informe um preço"),
      });

      //if the user informed tags, split them and add them to the tattoo data
      if (data.tags) {
        const tags = data.tags.replaceAll(' ', '').split(",");
        data.tags = tags;
      }

      data.author = "ratazima";
      await schema.validate(data, { abortEarly: false });
      const { id } = await addAvailableTattoo(data);
      await uploadFiles(filesToUpload, "Site_/AvailableTattoos/" + id);
      loadData();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages = {};

        err.inner.forEach((e) => {
          errorMessages = { ...errorMessages, [e.path!]: e.message };
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  };

  return (
    <Card
      sx={{
        background: "rgba(255,255,255,0.05)",
        padding: ".5rem 1rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3>Adicionar novo</h3>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input name="title" label="Título" placeholder="Título" />
        <Input name="description" label="Descrição" placeholder="Descrição" />
        <Input name="price" label="Preço" placeholder="Preço" />
        <Input name="size" label="Tamanho" placeholder="Tamanho" />
        <Input name="place" label="Sugestão de aplicação" placeholder="place" />
        <Input name="position" label="Posição (% %)" placeholder="position" />
        <Input name="zoom" label="Zoom (%)" placeholder="zoom" />
        <Input name="tags" label="Tags" placeholder="Tags" />

        <ImageUpload
          setFilesToUpload={setFilesToUpload}
          filesToUpload={filesToUpload}
          label="Selecionar Arquivos"
        />

        <Button type="submit">Adicionar</Button>
      </Form>
    </Card>
  );
};

const AvailableList = ({ tattoos, loadData }: any) => {
  const [expand, setExpand] = useState<boolean>(false);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Card
      sx={{
        background: "rgba(255,255,255,0.05)",
        padding: ".5rem 1rem",
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3 style={{ margin: ".5rem 0" }}>Gerenciar Atuais</h3>
        {tattoos[0] ? (
          <Button
            size="small"
            onClick={() => {
              setExpand(!expand);
            }}
          >
            Mostrar
          </Button>
        ) : (
          <CircularProgress size={24} />
        )}
      </div>

      {expand && tattoos && (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
          {tattoos.map((tattoo: any) => (
            <TattooCard data={tattoo} key={tattoo.id} loadData={loadData} />
          ))}
        </div>
      )}
    </Card>
  );
};
