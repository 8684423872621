import React, { Dispatch, SetStateAction, useState } from "react";
import { useDropzone } from "react-dropzone";
import Button from "@mui/material/Button";

interface iProps {
  setFilesToUpload: Dispatch<SetStateAction<any[]>>;
  filesToUpload: Array<any>;
  label: string;
}

export default function ImageUpload(props: iProps) {
  const { setFilesToUpload, filesToUpload, label } = props;
  const [expand, setExpand] = useState<boolean>(false);
  
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => onFilesDropped(files),
  });

  const onFilesDropped = (files: any) => {
    const newFiles = [...filesToUpload, ...files];
    setFilesToUpload(newFiles);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h2 className="form-section-label">{label}</h2>
        <Button
          color="error"
          onClick={() => {
            setFilesToUpload([]);
          }}
        >
          Limpar Arquivos
        </Button>
      </div>
      <section className="container">
        <div
          {...getRootProps({ className: "dropzone" })}
          style={{
            padding: "25px",
            borderRadius: "8px",
            background: "rgba(255,255,255,.05)",
          }}
        >
          <input {...getInputProps()} />
          <p>Arraste e solte os arquivos aqui...</p>
        </div>
        <aside>
          <div>
            {filesToUpload.map((file: any) => (
              <>
                {file.name} - {Math.trunc(file.size / 1024)} kb <br />
              </>
            ))}
          </div>
        </aside>
      </section>
    </>
  );
}
