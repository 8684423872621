import app from "./firebase";
import { collection, getFirestore, doc, updateDoc, getDoc, addDoc, Timestamp, getDocs, deleteDoc } from "firebase/firestore";
import { deleteFiles, getFilesURLs } from "./storage";



const db = getFirestore(app);

//INFO DISPLAY
export async function updateInfoDisplay(newInfo: any) {
  const docRef = await doc(db, "SiteContent/InfoDisplay");
  updateDoc(docRef, newInfo);
}
export async function getInfoDisplay() {
  const docRef = await doc(db, "SiteContent/InfoDisplay");
  return await (await getDoc(docRef)).data();
}

//AVAILABLE TATTOOS
export async function addAvailableTattoo(newTattoo: any) {
  const docRef = await addDoc(collection(db, "AvailableTattoos"), { ...newTattoo, createdAt: Timestamp.now() });
  return { id: docRef.id, docRef }
}

export async function getAvailableTattoos() {
  const querySnapshot = await getDocs(collection(db, "AvailableTattoos"));
  const tattoos = await Promise.all(querySnapshot.docs.map(async (doc) => {

    return {
      id: doc.id,
      ...doc.data(),
      urls: await getFilesURLs("Site_/AvailableTattoos/" + doc.id + "/")
    }

  }))

  return tattoos;
}
export async function updateAvailableTattoos(tattooId: string, tattooData: any) {
  const docRef = doc(db, "AvailableTattoos", tattooId);
  return await updateDoc(docRef, tattooData);
}

export async function deleteAvailableTattoo(tattooId: string) {
  try {
    await deleteFiles("Site_/AvailableTattoos/" + tattooId + "/");
    await deleteDoc(doc(db, "AvailableTattoos", tattooId));
    return true;
  } catch (e) {
    return false;
  }
}
export async function getAvailableTattoo(id: string) {
  const docRef = doc(db, "AvailableTattoos", id);
  const docSnap = await getDoc(docRef);

  if(!docSnap.exists()) return null;

  const data = docSnap.data();
  const urls = await getFilesURLs("Site_/AvailableTattoos/" + id + "/");

  return {
    id: docSnap.id,
    ...docSnap.data(),
    urls: await getFilesURLs("Site_/AvailableTattoos/" + docSnap.id + "/") 
  }
}


//GALLERY
export async function addGalleryImage(newImage: any) {
  const docRef = await addDoc(collection(db, "Gallery"), { ...newImage, createdAt: Timestamp.now() });
  return { id: docRef.id, docRef }
}

export async function getGalleryImages() {
  const querySnapshot = await getDocs(collection(db, "Gallery"));
  const tattoos = await Promise.all(querySnapshot.docs.map(async (doc) => {

    return {
      id: doc.id,
      ...doc.data(),
      urls: await getFilesURLs("Site_/Gallery/" + doc.id + "/")
    }

  }))

  return tattoos;
}

export async function updateGalleryImage(imageId: string, imageData: any) {
  const docRef = doc(db, "AvailableTattoos", imageId);
  return await updateDoc(docRef, imageData);
}

export async function deleteGalleryImage(imageId: string) {
  try {
    await deleteFiles("Site_/Gallery/" + imageId + "/");
    await deleteDoc(doc(db, "Gallery", imageId));
    return true;
  } catch (e) {
    return false;
  }
}