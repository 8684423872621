import { styled } from "@mui/system";
import { RatazimaTheme } from "../../../../Theme";
import { Form } from "@unform/web";
import Input from "../../../../common/Form/input";
import { useRef, useState } from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import DialogModal from "../../../../common/DialogModal";

import Button from "@mui/material/Button";
import axios from "axios";

export default function OrcamentoForm() {
  const formRef = useRef<FormHandles>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleWhatsappSubmit = async (data: any) => {
    const { name, email, description, size, placement } = data;
    const whatsapp = `https://wa.me/5543996834944?text=Olá, gostaria de solicitar um orçamento. Nome ${name}. Email: ${email}. Ideia: ${description}. Tamanho: ${size}. Lugar: ${placement}`;
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required("Por favor, informe seu nome"),
        description: Yup.string().required("Por favor, fale um pouco sobre sua ideia."),
        email: Yup.string()
        .email("Por favor, informe um email válido")
        .required("Por favor, informe seu email"),
      });
      
      await schema.validate(data, { abortEarly: false });
      console.log(whatsapp);
      window.open(whatsapp);

      // send the form data to the server asynchronously and log the response, then, clear the form and the errors
      await axios.post(
        "https://us-central1-site-ratazima.cloudfunctions.net/hello/contact/estimate",
        data
      );
      // setOpen(true);
      formRef.current?.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages = {};

        err.inner.forEach((e) => {
          errorMessages = { ...errorMessages, [e.path!]: e.message };
        });

        formRef.current!.setErrors(errorMessages);
      }
      else{
        console.log(err);
      }
    }
  }

  const handleSubmit: SubmitHandler = async (data: any, { reset }) => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required("Por favor, informe seu nome"),
        email: Yup.string()
          .email("Por favor, informe um email válido")
          .required("Por favor, informe seu email"),
        description: Yup.string().required(
          "Por favor, fale um pouco sobre sua ideia"
        ),
        phone: Yup.string().required("Por favor, informe seu telefone"),
      });

      await schema.validate(data, { abortEarly: false });

      console.log({ ...data });

      // send the form data to the server asynchronously and log the response, then, clear the form and the errors
      await axios.post(
        "https://us-central1-site-ratazima.cloudfunctions.net/hello/contact/estimate",
        data
      );
      reset();
      setOpen(true);
      formRef.current?.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages = {};

        err.inner.forEach((e) => {
          errorMessages = { ...errorMessages, [e.path!]: e.message };
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  };

  return (
    <OrcamentoFormContainer>
      <DialogModal open={open} setOpen={setOpen} title="Orçamento enviado!" text="Solicitação de orçamento enviada! O tatuador entrará em contato em breve."/>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Input label="Nome" name="name"></Input>
        <div style={{ display: "flex", width: "100%" }}>
          <Input label="E-mail" name="email"></Input>
          <Input label="Celular" name="phone"></Input>
        </div>
        <Input
          label="Descreva a ideia do projeto para tatuar"
          name="description"
          multiline
          minRows={2}
        ></Input>

        <div style={{ display: "flex" }}>
          <Input label="Local do corpo" name="placement"></Input>
          <Input label="Tamanho (aprox. em cm)" name="size"></Input>
        </div>
        <div style={{display: "flex"}}>
          <Button
            endIcon={<MailIcon />}
            type="submit"
            variant="contained"
            color="secondary"
            size="medium"
            fullWidth
            sx={{ marginTop: "1rem", marginRight: ".5rem" }}
          >
            Enviar
          </Button>
          <Button
            endIcon={<WhatsAppIcon />}
            variant="outlined"
            color="success"
            size="medium"
            fullWidth
            onClick={() => handleWhatsappSubmit(formRef.current?.getData())}
            sx={{ marginTop: "1rem" }}
          >
            Enviar
          </Button>
        </div>
      </Form>
    </OrcamentoFormContainer>
  );
}

const OrcamentoFormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  width: 100%;

  Form {
    width: 100%;
    Input {
    }
  }

  @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    width: 100%;

    Form {
      overflow: hidden;
      width: 95%;
    }
  }
`;
