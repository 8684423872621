import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import AvailableTattoos from "./AvailableTattoos";
import GalleryManagement from "./GalleryManagement";
import InformationDisplay from "./InformationDisplay";
import { AdminContainer } from "./styles";
import Button from "@mui/material/Button";

export default function AdminDashboard() {
  document.title = "Ratazima | Painel de Controle";
  const { currentUser, logout }: any = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Ratazima | Panel de Controle";
    console.log({ currentUser });
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser]);

  return (
    <AdminContainer>
      <h1 style={{color: "white"}}>Painel de Controle</h1>
      <InformationDisplay />
      <AvailableTattoos />
      <GalleryManagement />
      <Button onClick={logout}>Logout</Button>
    </AdminContainer>
  );
}
