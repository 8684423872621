import React, { useEffect, useState } from "react";
import { LoginContainer } from "./styles";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export default function DashboardLogin() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({ email: "", pass: "" });
  const { currentUser, login }: any = useAuth();

  useEffect(() => {
    if (currentUser) {
      console.log(currentUser)
      navigate("/admin-dashboard");
    }
  }, [currentUser]);

  const handleSubmit = () => {
    setError("");
    if (loginData.email && loginData.pass) {
      setLoading(true);

      login(loginData.email, loginData.pass)
        .then((user: any) => {
          setLoading(false);
          setError("");
        })
        .catch((e: any) => {
          console.log({ e });
          if (e.code === "auth/user-not-found") {
            setError("Usuário nao cadastrado.");
          } else {
            setError(e.message);
          }

          setLoading(false);
        });
    } else {
      setError("Informe todos os dados de login.");
    }
  };

  return (
    <LoginContainer>
      <Card className="login-form">
        <h1>Admin Login</h1>
        <TextField
          label="E-mail"
          type="email"
          autoComplete="email-admin-ratazima"
          variant="outlined"
          value={loginData.email}
          onChange={(event) =>
            setLoginData((prev) => ({ ...prev, email: event.target.value }))
          }
          size="medium"
          className="login-field"
        />
        <TextField
          label="Senha"
          type="password"
          autoComplete="password-admin-ratazima"
          variant="outlined"
          value={loginData.pass}
          onChange={(event) =>
            setLoginData((prev) => ({ ...prev, pass: event.target.value }))
          }
          size="medium"
          className="login-field"
        />

        <Button
          variant="contained"
          size="large"
          sx={{ fontWeight: 700, marginTop: "1rem" }}
          onClick={handleSubmit}
        >
          Entrar
        </Button>

        {error && (
          <Alert severity="error" className="login-field">
            {error}{" "}
          </Alert>
        )}
      </Card>
    </LoginContainer>
  );
}
