import { styled } from "@mui/system";
import { RatazimaTheme } from "../../Theme";

export const AdminContainer = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin-top: ${RatazimaTheme.dimensions.navBarHeight};
    
    
    .comming-soon {
        p{
            text-align: center;
            color: whitesmoke;
            font-size: 1.5rem;
            font-weight: 500;
        }
    }
    
    .icons {
        display: flex;
        align-items: center;
        justify-content: center;

        
        .icon-social-media {
            color: whitesmoke;
            font-size: 3rem;
        }
    }
    
`;
