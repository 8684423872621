import { Routes, Route, Outlet } from "react-router-dom";
import Disponiveis from "./pages/Disponiveis";
import Galeria from "./pages/Galeria";
import Hearts from "./pages/LandingPages/Hearts";
import AdminDashboard from "./pages/AdminDashboard";
import DashboardLogin from "./pages/DashboardLogin";
import TattooDisponivel from "./pages/TattooDisponivel";
import Home from "./pages/Home";
import Navbar from "./common/Navbar";
import Footer from "./common/Footer";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import DisponiveisCoracoes from "./pages/DisponiveisCoracoes";

export default function MainRoutes() {
  const navigate = useNavigate();

  useEffect(() => {
    //redirecting all pages to the landing page
    //navigate("/flash/broken-hearts");
  }, []);

  return (
    <>
      {/* <Navbar /> */}
      <Routes>
        <Route element={<PageLayout />}>
          <Route index element={<Home />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/login" element={<DashboardLogin />} />
          <Route path="/disponiveis/id/:id" element={<TattooDisponivel />} />
          <Route path="/disponiveis/:tag" element={<Disponiveis />} />
          <Route path="/disponiveis/" element={<Disponiveis />} />
          <Route path="/broken-hearts/" element={<DisponiveisCoracoes />} />
          
          <Route path="/galeria/" element={<Galeria />} />
          <Route path="/:section" element={<Home />} />
        </Route>
        <Route path="/flash/broken-hearts" element={<Hearts />} />
      </Routes>
    </>
  );
}

export function PageLayout() {
  return (
    <>
      <Navbar />
      <Outlet />
      <Divider sx={{ background: "#303030" }} />
      <Footer />
    </>
  );
}
