import IconButton from "@mui/material/IconButton";
import React from "react";
import { Link } from "react-router-dom";
import { FooterContainer } from "./styles";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function Footer() {
  return (
    <FooterContainer>
      <div className="footer-top-divider"></div>
      <Link
        className="logoLink"
        to="/"
        onClick={() => {
          backToTop();
        }}
      >
        {/* <img
                            className="logo"
                            src="/images/Simbolo_Branco.svg"
                            alt="Logo"
                        /> */}
        <h1 className="logotype">RATAZIMA</h1>
      </Link>

      <ul className="nav-footer">
        <li>
          <Link className="link" to="/?section=orcamento">
            Orçamento
          </Link>
        </li>
        <li>
          <Link className="link" to="/disponiveis">
            Disponíveis
          </Link>
        </li>
        <li>
          <Link className="link" to="/galeria">
            Galeria
          </Link>
        </li>
        <li>
          <Link className="link" to="/?section=contato">
            Contato
          </Link>
        </li>
      </ul>
      <div className="icons">
        <IconButton
          aria-label="Instagram"
          onClick={() => {
            window.location.href = "https://www.instagram.com/ratazima/";
          }}
        >
          <InstagramIcon className="icon-social-media" />
        </IconButton>
        <IconButton
          aria-label="Facebook"
          onClick={() => {
            window.location.href = "https://www.facebook.com/ratazima";
          }}
        >
          <FacebookIcon className="icon-social-media" />
        </IconButton>
        <IconButton
          aria-label="Whatsapp"
          onClick={() => {
            window.location.href = "https://wa.me/" + "554396834944";
          }}
        >
          <WhatsAppIcon className="icon-social-media" />
        </IconButton>
      </div>
      <p>{new Date().getFullYear()}, Ratazima. Todos os direitos reservados.</p>
    </FooterContainer>
  );
}

function backToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
