import React from "react";
import { styled } from "@mui/system";
import { RatazimaTheme } from "../../../Theme";
import bioImage from "../../../images/perfil_ratazima_high_result.webp";

export default function BioHome() {
  return (
    <BioHomeContainer>
      <div className="bio-home-content-wraper">
        <div className="bio-home-image">
          <div className="side-text-box">
            <p> SINCE 2015 </p>
          </div>
        </div>
        <div className="bio-home-text">
          <h1>
            RAFAEL
            <br />
            <span>TAZIMA</span>
          </h1>
          <p>
            Meu nome é <strong>Rafael Tazima</strong>, também conhecido como Ratazima. Minha
            primeira experiência com a tatuagem foi em 2015, que no início foi
            marcada por estudos de desenhos e pequenas tatuagens em colegas,
            enquanto concluía minha graduação em Design. Em 2017, concluída a
            graduação, fui convidado a trabalhar no estúdio Laia em Curitiba -
            PR, onde permaneci e cresci profissionalmente por 4 anos. Agora, em
            2022, abri meu estúdio privado, onde trabalho desde então. Além de
            Curitiba, ocasionalmente tatuo em Londrina - PR, São Paulo - SP e
            futuramente pretendo atuar em outras cidades.
          </p>
        </div>
      </div>
    </BioHomeContainer>
  );
}

const BioHomeContainer = styled("section")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 100%;

  .bio-home-content-wraper {
    display: flex;
    align-items: center;
    max-width: ${RatazimaTheme.dimensions.maxColumnWidth};
    height: 100%;

    --bio-home-ratio: 50%;

    .bio-home-image {
      position: relative;
      background: url(${bioImage});
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      padding-top: calc(var(--bio-home-ratio) * 0.9);
      transition: all 0.1s ease-out;

      /* min-height: 100%; */
      margin-left: 2rem;
      flex: 1;
      /* width: var(--bio-home-ratio); */

      /* border: 1px solid white; */

      &:hover {
        background-size: 102%;
      }

      .side-text-box {
        display: flex;
        align-items: center;
        width: 5rem;
        /* border: 1px solid blue; */
        background: ${(props) => props.theme.palette.primary.main};
        position: absolute;
        top: 0;
        bottom: 0;
        left: -2.5rem;
        margin: 15% 0;

        p {
          line-height: 0;
          position: absolute;
          right: -0.5rem;
          bottom: 60%;
          white-space: nowrap;
          font-weight: 700;
          font-size: 1.2rem;
          transform: rotate(-90deg);

          &:after {
            position: absolute;
            content: "";
            height: 3px;
            bottom: 0;
            right: 110%;
            left: -5rem;
            top: 0;
            background: black;
          }
        }
      }
    }
    .bio-home-text {
      color: ${(props) => props.theme.palette.primary.main};
      text-align: right;
      width: calc(100% - var(--bio-home-ratio));
      padding: 1rem 4rem 1rem 6rem;

      h1 {
        --name-font-size: 1.5rem;
        font-weight: 300;
        font-size: var(--name-font-size);
        margin-bottom: 2rem;
        span {
          font-size: calc(var(--name-font-size) * 2);
          font-weight: 800;
        }
      }

      p {
        line-height: 1.3;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          width: 3px;
          margin: 0 auto;
          bottom: 0px;
          right: -1.5rem;
          top: 0;
          background: ${(props) => props.theme.palette.secondary.light};
        }
      }
    }

    @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0;

      .bio-home-image {
        width: 80%;
        padding-top: 80%;
        margin-left: 8%;
      }

      .bio-home-text {
        h1 {
          font-size: calc(${RatazimaTheme.fontSizes.mainTitles} * 1);
          span {
            font-size: calc(${RatazimaTheme.fontSizes.mainTitles} * 1.2);
          }
        }
        text-align: center;
        width: 85%;
        padding: 1rem 0;
        p {
          margin-top: 2rem;
          &:after {
            position: absolute;
            content: "";
            width: 0%;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: -1.3rem;
            height: 3px;
            background: ${(props) => props.theme.palette.secondary.light};
          }
        }
      }
    }
  }
`;
