import chroma from "chroma-js"


export const RatazimaTheme = {
    colors: {
        red: "#f26b6b",
        blue: "#1766ab",
        green: "#41BA91",
        black: "#110f10",
        darkgrey: "#161b1f",
        white: "#FFFFFF",
        ratazimaPurple: "#2e0",
    },
    dimensions: {
        maxColumnWidth: "1024px",
        navBarHeight: "90px",
        footerHeight: "600px",
    },
    breakpoints: {
        large: "1500px",
        desktop: "1200px",
        tablet: "1024px",
        mobile: "768px",
        tablet_number: 1024,
    },
    fontSizes: {
        mainTitles: "3rem",
    }
}