import React, { useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import { Form } from "@unform/web";
import Button from "@mui/material/Button";
import Input from "../../../common/Form/input";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";
import {
  deleteAvailableTattoo,
  updateAvailableTattoos,
} from "../../../firebase/firestore";

export default function TattooCard({ data, loadData }: any) {
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    console.log("data", data.urls[0]);
  }, [data.urls[0]]);

  const handleDelete = async () => {
    const { id } = data;
    deleteAvailableTattoo(id).then(() => {
      loadData();
    });
  };

  const handleSubmit: SubmitHandler = async (data: any, { reset }) => {
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        title: Yup.string().required("Por favor, informe o titulo"),
        description: Yup.string().required("Por favor, informe uma descriçao"),
        price: Yup.string().required("Por favor, informe um preço"),
      });

      //if the user informed tags, split them and add them to the tattoo data
      if (data.tags) {
        const tags = data.tags.replaceAll(' ', '').split(",");
        data.tags = tags;
      }

      data.author = "ratazima";
      console.log(data);
      await schema.validate(data, { abortEarly: false });
      await updateAvailableTattoos(data.id, data);
      loadData();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages = {};

        err.inner.forEach((e) => {
          errorMessages = { ...errorMessages, [e.path!]: e.message };
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  };

  return data ? (
    <Card
      sx={{
        padding: "1rem 1rem",
        display: "flex",
        flexDirection: "column",
        margin: "5px",
        width: "auto",
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          paddingTop: "100%",
          backgroundImage: `url(${data.urls[0]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "5px",
          overflow: "hidden",
          marginBottom: ".8rem",
        }}
      >
        <p
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            background: "rgba(0,0,0,0.9)",
            fontSize: ".8rem",
            textAlign: "center",
            padding: ".5rem 0",
            color: "white",
          }}
        >
          ID: {data.id}
        </p>
      </div>
      <Form onSubmit={handleSubmit} ref={formRef} initialData={data}>
        <Input label="ID" name="id" disabled style={{ display: "none" }} />
        <Input label="Título" name="title" />
        <Input label="Descrição" name="description" multiline minRows={2} />
        <Input label="Preço" name="price" type="text" />
        <Input name="size" label="Tamanho" placeholder="Tamanho" />
        <Input name="place" label="Sugestão de aplicação" placeholder="place" />
        <Input name="position" label="Posição (% %)" placeholder="position" />
        <Input name="zoom" label="Zoom (%)" placeholder="zoom" />
        <Input label="Tags" name="tags" type="text" />

        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="medium"
            sx={{ margin: ".5rem" }}
          >
            Atualizar
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="error"
            size="medium"
            sx={{ margin: ".5rem" }}
          >
            Excluir
          </Button>
        </div>
      </Form>
    </Card>
  ) : (
    <></>
  );
}
