import { styled } from "@mui/system";
import { RatazimaTheme } from "../../Theme";

export const FooterContainer = styled("footer")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 6rem 0; */
  color: #fff;
  

  .footer-top-divider {
    height: 2px;
    width: 100%;
    background: ${props=> props.theme.palette.secondary.main};
  }
  .logoLink {
    text-decoration: none;
    line-height: 3;
    .logotype {
      font-family: "Raleway";
      font-weight: 900;
      color: white;
      margin: 12px 24px;
    }
    .logo {
      width: 40px;
      height: 40px;
      margin: 24px 60px;
    }
  }

  .nav-footer {
    display: flex;
    li {
      .link {
        font-family: "Raleway";
        color: #bbb;
        text-decoration: none;
        font-weight: 400;
        font-size: 18px;
        padding: 0;
      }
      margin: 0px 36px;
    }
  }

  .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3rem 0 2rem 0;

        
        .icon-social-media {
            color: whitesmoke;
            font-size: 1.8rem;
        }
    }



  p {
    font-family: "Raleway";
    margin: 1rem 0 2rem 0;
    text-align: center;
  }

  @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
    .nav-footer {
      flex-direction: column;
      align-items: center;
      li {
        margin: 15px 0;
      }
    }
  }
`;
