import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { RatazimaTheme } from "../../../Theme";
import { getInfoDisplay, updateInfoDisplay } from "../../../firebase/firestore";

export default function InformationDisplay() {
  const [visibility, setVisibility] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [expand, setExpand] = useState<boolean>(false);

  const [buttonConfig, setButtonConfig] = useState({
    visibility: false,
    text: "",
    color: "#fff",
    linkTo: "/",
  });

  function handleSubmit() {
    if ((visibility && message) || !visibility) {
      console.log("Dados salvos com sucesso!");
      updateInfoDisplay({ message: message, visibility: visibility });
    } else {
      console.log("Favor informar uma mensagem para o letreiro.");
    }
  }

  useEffect(() => {
    getInfoDisplay().then((info) => {
      console.log(info);
      if(info) { 
        setVisibility(info.visibility);
        setMessage(info.message);
      }
    });
  }, []);

  return (
    <>
      <Card
        sx={{
          padding: "1rem 2rem",
          display: "flex",
          flexDirection: "column",
          width: RatazimaTheme.dimensions.maxColumnWidth,
          margin: "2rem",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ margin: ".5rem 0" }}>Letreiro Variável</h2>
          <Button
            size="small"
            onClick={() => {
              setExpand(!expand);
            }}
          >
            Mostrar
          </Button>
        </div>

        {expand && (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibility}
                  onChange={() => setVisibility(!visibility)}
                />
              }
              label="Mostrar Letreiro"
            />

            {visibility && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  label="Mensagem"
                  autoComplete="mensagem-letreiro"
                  variant="outlined"
                  disabled={!visibility}
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  size="small"
                />

                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={buttonConfig.visibility}
                      onChange={() =>
                        setButtonConfig({
                          ...buttonConfig,
                          visibility: !buttonConfig.visibility,
                        })
                      }
                    />
                  }
                  label="Incluir Botão"
                /> */}
                {buttonConfig.visibility && (
                  <div style={{ display: "flex" }}>
                    <TextField
                      label="Texto do Botão"
                      autoComplete="texto-botao"
                      variant="outlined"
                      value={buttonConfig.text}
                      onChange={(event) =>
                        setButtonConfig({
                          ...buttonConfig,
                          text: event.target.value,
                        })
                      }
                      size="small"
                      sx={{ width: "50%" }}
                    />
                    <TextField
                      label="Link do Botão"
                      autoComplete="texto-botao"
                      variant="outlined"
                      value={buttonConfig.linkTo}
                      onChange={(event) =>
                        setButtonConfig({
                          ...buttonConfig,
                          linkTo: event.target.value,
                        })
                      }
                      size="small"
                      sx={{ width: "50%" }}
                    />
                    <TextField
                      label="Cor do Botão"
                      autoComplete="texto-botao"
                      variant="outlined"
                      value={buttonConfig.color}
                      onChange={(event) =>
                        setButtonConfig({
                          ...buttonConfig,
                          color: event.target.value,
                        })
                      }
                      size="small"
                      sx={{ width: "50%" }}
                    />
                  </div>
                )}
              </div>
            )}
            <Button
              variant="contained"
              size="large"
              sx={{ fontWeight: 700, marginTop: "1rem" }}
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </div>
        )}
      </Card>
    </>
  );
}
