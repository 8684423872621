import { styled } from "@mui/system";
import { RatazimaTheme } from "../../../Theme";
import OrcamentoForm from "./FormOrcamento";
import orcamentoImage from "../../../images/orcamento_ratazima_result.webp";

export default function OrcamentoHome() {
  return (
    <OrcamentoHomeContainer id="orcamento-section">
      <div className="orcamento-home-content-wraper">
        <div className="orcamento-home-texto">
          <h1 className="orcamento-home-title">
            SOLICITE <br /> <span>UM ORÇAMENTO</span>
          </h1>
          <p>
            Preencha todos os campos e envie sua solicitação via e-mail ou
            whatsapp. Retornarei o mais rápido possível.
          </p>
          <OrcamentoForm />
        </div>
        <div className="orcamento-home-image"></div>
      </div>
    </OrcamentoHomeContainer>
  );
}

const OrcamentoHomeContainer = styled("section")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  width: 100%;

  /* border: 1px solid white; */

  .orcamento-home-content-wraper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: ${RatazimaTheme.dimensions.maxColumnWidth};
    height: 100%;

    /* border: 1px solid red; */

    .orcamento-home-texto {
      color: ${(props) => props.theme.palette.primary.main};
      text-align: center;
      width: 50%;
      font-weight: 300;
      margin-right: 2rem;

      /* border: 1px solid white; */

      p {
        text-align: left;
        margin-top: 1rem;
        padding: 0 0rem;
      }

      .orcamento-home-title {
        width: min-content;
        white-space: nowrap;
        position: relative;
        font-size: 24px;
        font-weight: 400;
        /* margin: 0 0 2rem 0; */
        text-align: left;

        span {
          font-size: 48px;
          font-weight: 800;
          text-align: right;
          margin-left: 2rem;
          /* &:after {
            position: absolute;
            z-index: 2;
            content: "";
            height: 3px;
            left: 105%;
            right: -5ch;
            bottom: 30%;
            background: ${(props) => props.theme.palette.secondary.main};
          } */
        }
      }
    }

    .orcamento-home-image {
      position: relative;
      width: 40%;
      padding-top: 50%;
      background: url(${orcamentoImage}) no-repeat center center;
      background-size: auto 100%;
      /* border: 1px solid red; */
    }

    @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      .orcamento-home-image {
        display: none;
      }
      .orcamento-home-texto {
        margin: 0;
        width: 100%;
        p {
          text-align: left;
          margin-top: 2rem;
          padding: 0 1rem;
        }
        .orcamento-home-title {
          width: 100%;
          text-align: center;
          span {
            margin: 0;
            padding: 0;
            font-size: calc(${RatazimaTheme.fontSizes.mainTitles} * 0.9);
            text-align: center;
            &:after {
              right: 0;
              left: 70%;
              top: 0.5ch;
            }
            &:before {
              position: absolute;
              z-index: 2;
              content: "";
              height: 3px;
              left: 0;
              right: 70%;
              top: 0.5ch;
              /* width: 100px; */
              background: ${(props) => props.theme.palette.secondary.main};
            }
          }
        }
      }

      .orcamento-home-image {
        width: 100%;
        padding-top: 80%;
        margin-top: 2rem;
      }
    }
  }
`;
