import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getAvailableTattoo } from "../../firebase/firestore";
import { styled } from "@mui/system";
import ListaTags from "../../common/ListaTags";
import { RatazimaTheme } from "../../Theme";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { Form } from "@unform/web";
import Input from "../../common/Form/input";
import { FormHandles, SubmitHandler } from "@unform/core";
import axios from "axios";
import * as Yup from "yup";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import VerticalSpacer from "../../common/VerticalSpacer";
import DialogModal from "../../common/DialogModal";

export default function TattooDisponivel() {
  const { id } = useParams();
  const [tattoo, setTattoo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [imgsSrc, setImgsSrc] = useState({ main: "", placement: "" });
  const [imgDisplay, setImgDisplay] = useState<"main" | "placement">("main");
  const formRef = useRef<FormHandles>(null);
  const [open, setOpen] = useState<boolean>(false);
  

  const handleWhatsappSubmit = async (data: any) => {
    const { name, email, message } = data;
    const whatsapp = `https://wa.me/5543996834944?text=Olá, gostaria de saber mais sobre a tatuagem: ${tattoo.id}. Nome: ${name}. Email: ${email}. Mensagem: ${message}`;
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required("Por favor, informe seu nome"),
        email: Yup.string()
        .email("Por favor, informe um email válido")
        .required("Por favor, informe seu email"),
      });
      
      await schema.validate(data, { abortEarly: false });
      console.log(whatsapp);
      window.open(whatsapp);

      // send the form data to the server asynchronously and log the response, then, clear the form and the errors
      await axios.post(
        "https://us-central1-site-ratazima.cloudfunctions.net/hello/contact/book",
        { ...data, id: tattoo.id }
      );
      // setOpen(true);
      formRef.current?.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages = {};

        err.inner.forEach((e) => {
          errorMessages = { ...errorMessages, [e.path!]: e.message };
        });

        formRef.current!.setErrors(errorMessages);
      }
      else{
        console.log(err);
      }
    }
  };

  const handleSubmit: SubmitHandler = async (data: any, { reset }) => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required("Por favor, informe seu nome"),
        email: Yup.string()
          .email("Por favor, informe um e-mail válido")
          .required("Por favor, informe seu e-mail"),
        phone: Yup.string().required("Por favor, informe seu telefone"),
      });

      await schema.validate(data, { abortEarly: false });

      // console.log({ ...data });

      // send the form data to the server asynchronously and log the response, then, clear the form and the errors
      await axios.post(
        "https://us-central1-site-ratazima.cloudfunctions.net/hello/contact/book",
        { ...data, id: tattoo.id }
      );
      reset();
      setOpen(true);
      formRef.current?.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages = {};

        err.inner.forEach((e) => {
          errorMessages = { ...errorMessages, [e.path!]: e.message };
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getAvailableTattoo(id).then((tattoo) => {
        if (tattoo) {
          setTattoo(tattoo);
          setImgsSrc({ main: tattoo.urls[0], placement: tattoo.urls[1] });
          const myImage = new Image();

          myImage.onload = () => {
            setLoading(false);
          };
          myImage.src = tattoo.urls[0];

          const myPlaceImage = new Image();
          myPlaceImage.src = tattoo.urls[1];
        }
      });
    }
  }, [id]);

  return (
    <SectionContainer>
      {tattoo && (
        <Box>
          <DialogModal
            open={open}
            setOpen={setOpen}
            title="Reserva solicitada!"
            text="Reserva solicitada! O tatuador entrará em contato em breve para agendar a data e horário da sessão!"
          />
          <div style={{ width: "100%" }}>
            <MyImage
              style={
                loading
                  ? {}
                  : { backgroundImage: `url(${imgsSrc[imgDisplay]})` }
              }
            >
              {loading && (
                <LinearProgress color="primary" sx={{ width: "100%" }} />
              )}
              {!loading && (
                <Button
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    imgDisplay === "main"
                      ? setImgDisplay("placement")
                      : setImgDisplay("main");
                  }}
                >
                  {imgDisplay === "main" ? "Ver aplicação" : "Ver desenho"}
                </Button>
              )}
            </MyImage>
          </div>
          <Info>
            <div>
              <h1 className="title">{tattoo.title}</h1>
              <h2 className="price">
                <span>R$</span> {tattoo.price}
              </h2>
              <h2 className="size">{tattoo.size}</h2>
              <div className="tags">

                {!tattoo.tags.includes("coração") && <ListaTags tags={tattoo.tags} />}
              </div>
              <h3 style={{ marginBottom: 20, fontSize: 16 }}>
                {tattoo.description}
              </h3>
            </div>
            <VerticalSpacer height="1rem" />
            <div className="box-reserva">
              <h2>RESERVAR ESTE DESENHO</h2>
              <p style={{ fontSize: 12, marginTop: 10 }}>
                Cada desenho acompanha uma sugestão de posicionamento, mas é
                possível alterar o local do corpo a ser tatuado. Conforme a
                necessidade, o tamanho dos desenhos pode ser alterado para se
                encaixar no corpo do cliente. Preencha todos os campos e envie
                sua mensagem via e-mail ou whatsapp. Retornarei o mais rápido
                possível.
              </p>
              <Form
                onSubmit={handleSubmit}
                ref={formRef}
                style={{ width: "100%" }}
              >
                <Input name="name" label="Nome" color="secondary" />
                <Input name="email" label="E-mail" />
                <Input name="phone" label="Telefone" />
                <Input name="message" label="Mensagem" multiline rows={2} />
                <VerticalSpacer height="1rem" />
                <div
                  className="botoes"
                  style={{ display: "flex", width: "100%" }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    type="submit"
                    endIcon={<MailIcon />}
                    sx={{ marginRight: "5px" }}
                  >
                    Enviar Mensagem
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="success"
                    onClick={() => {
                      handleWhatsappSubmit(formRef.current?.getData());
                    }}
                    endIcon={<WhatsAppIcon />}
                  >
                    Enviar Mensagem
                  </Button>
                </div>
              </Form>
            </div>
          </Info>
        </Box>
      )}
    </SectionContainer>
  );
}

const MyImage = styled("div")`
  background: rgba(0, 0, 0, 0);
  background-size: cover;
  background-position: center center;
  width: 100%;
  padding-top: 120%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  transition: background 0.5s ease-in-out;

  Button {
    border-radius: 0;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
    font-weight: 600;
  }

  @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
    width: 100%;
    height: auto;
    padding-top: 125%;
  }
`;

const Box = styled("div")`
  display: grid;
  width: 100%;
  max-width: ${RatazimaTheme.dimensions.maxColumnWidth};
  grid-template-columns: 5fr 4fr;
  padding: 1.2rem;
  background: black;
  border-radius: 6px;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.25);
  color: black;

  @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    width: 100%;
    padding: 0.5rem;
  }
`;
const Info = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 2rem;
  text-align: left;
  width: auto;
  color: white;

  .title {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: 2rem;
    position: relative;
    width: 100%;
    &::after {
      content: "";
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      width: 50%;
      height: 3px;
      background: ${(props) => props.theme.palette.secondary.main};
    }
  }

  .price {
    span {
      font-size: 1.2rem;
      font-weight: 800;
    }
    font-size: 1.4rem;
  }

  .size {
    font-weight: 500;
  }

  .tags {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    a {
      margin-right: 0.4rem;
      margin-bottom: 0.4rem;
    }
  }

  .box-reserva {
    background-color: rgba(255, 255, 255, 0.075);
    padding: 12px;
    border-radius: 8px;
    h2 {
      text-align: center;
      margin: 12px 0;
    }
    p {
      text-align: center;
    }
  }
  @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
    margin: 2rem 0;
    padding: 0 2vw;

    .botoes {
      flex-direction: column;
      Button {
        margin-bottom: 0.35rem;
      }
    }
  }
`;

const SectionContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;

  padding-top: calc(${RatazimaTheme.dimensions.navBarHeight} + 1rem);
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  color: white;

  @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
    padding: 0;
    padding-top: calc(${RatazimaTheme.dimensions.navBarHeight} + 1rem);
  }
`;
