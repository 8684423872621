import { HomeContainer } from "./styles";
import Hero from "./Hero";
import BioHome from "./Bio";
import ProcessoHome from "./Processo";
import VerticalSpacer from "../../common/VerticalSpacer";
import EstudioHome from "./Orcamento";
import DisponiveisHome from "./Disponiveis";
import ContatoHome from "./Contato";
// import ClientesHome from "./Clientes";

import { useEffect, useState } from "react";
import useWindowDimensions from "../../Theme/WindowDimensions";
import { useLocation, useNavigate } from "react-router-dom";
import GaleriaHome from "./Galeria";
import HeartsHome from "./DisponiveisCoracoes";

export default function Home() {
  const { width } = useWindowDimensions();
  const [verticalSpacing, setVerticalSpacing] = useState("15rem");
  const search = useLocation().search;
  const section = new URLSearchParams(search).get("section");
  const navigate = useNavigate();

  const [imagesLoaded, setImagesLoaded] = useState({
    imgsToLoad: 0,
    imgsLoaded: 0,
  });

  useEffect(() => {
    if (
      imagesLoaded.imgsToLoad === imagesLoaded.imgsLoaded &&
      imagesLoaded.imgsToLoad > 0
    ) {
      if (section === "orcamento") {
        const orcamento_section = document.getElementById("orcamento-section");

        if (orcamento_section) {
          // window.scrollTo(0, orcamento_section.offsetTop);
          orcamento_section.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
        navigate("/?section=orcamento_");
      } else if (section === "contato") {
        document.getElementById("contato-section")?.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
        navigate("/?section=contato_");
      }
    }
  }, [imagesLoaded, search, section, navigate]);

  useEffect(() => {
    document.title = "Ratazima";
    if (width! < 768) {
      setVerticalSpacing("8rem");
    } else {
      setVerticalSpacing("15rem");
    }
  }, [width]);

  return (
    <HomeContainer>
      <Hero />
      <VerticalSpacer height={verticalSpacing} />
      <BioHome />
      <VerticalSpacer height={verticalSpacing} />
      <ProcessoHome />
      <VerticalSpacer height={verticalSpacing} />
      <EstudioHome />
      <VerticalSpacer height={verticalSpacing} />
      <DisponiveisHome setImagesLoaded={setImagesLoaded} />
      <VerticalSpacer height={verticalSpacing} />
      <HeartsHome />
      <VerticalSpacer height={"1rem"} />
      <GaleriaHome setImagesLoaded={setImagesLoaded} />
      <VerticalSpacer height={verticalSpacing} />
      <ContatoHome />
      <VerticalSpacer height={verticalSpacing} />
    </HomeContainer>
  );
}
