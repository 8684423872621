import React, { useEffect } from "react";
import { styled } from "@mui/system";
import { RatazimaTheme } from "../../../Theme";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import profilePic from "../../../images/Tazima-6-pb_home_result.webp";
import bgArtwork from "../../../images/artwork_fundo_home_result.webp";

export default function HomeHero() {
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    const mainImage = new Image();
    mainImage.src = profilePic;
    mainImage.onload = () => {
      setLoaded(true);
    };
  }, []);

  return (
    <Fade in={loaded} timeout={1000}>
      <HomeHeroContainer>
        <div className="hero-foreground">
          <div className="hero-headline">
            <h1>
              <br /> <span className="span-nome">RATAZIMA</span>
            </h1>
            <h2>Tattoo Artist - Curitiba - PR</h2>
          </div>
          <div className="hero-bg-square">
            {/* <img src="/images/Tazima-6-pb_home.png" alt="ratazima-foto-home" /> */}
            <div className="hero-image"></div>
          </div>
        </div>

        <Slide in={true} direction="right" timeout={2000}>
          <div className="bg-name">
            <p>
              RATA
              <br />
              ZIMA
            </p>
          </div>
        </Slide>
        <div style={{ opacity: 0.05 }}>
          <Fade in={true} timeout={5000}>
            <div className="bg-desenho"></div>
          </Fade>
        </div>
      </HomeHeroContainer>
    </Fade>
  );
}

const HomeHeroContainer = styled("section")`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  width: 100%;
  /* border: 1px solid red; */
  margin-top: ${RatazimaTheme.dimensions.navBarHeight};

  .bg-desenho {
    margin-top: 0px;
    z-index: -20;
    /* border: 2px solid yellow; */
    position: absolute;
    left: 50%;
    top: 0;
    right: 0;
    bottom: -50rem;
    opacity: 0.035;

    background: url(${bgArtwork});
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;

    @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
      left: 0;
      right: 0;
      bottom: -10rem;
      background-size: 110%;
      overflow: hidden;
    }
  }

  .bg-name {
    margin-top: -30px;
    z-index: -1;
    --text-width: 70vw;
    /* border: 2px solid yellow; */
    position: absolute;
    width: var(--text-width);
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    p {
      color: rgba(138, 138, 138, 0.04);
      font-weight: 900;
      font-size: calc(40vh);
      line-height: 1;
      margin: 0;
      padding: 0;
    }
    @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
      display: none;
    }
  }
  .hero-foreground {
    max-width: ${RatazimaTheme.dimensions.maxColumnWidth};
    width: 100%;
    /* border: 1px solid ${(props) => props.theme.palette.secondary.main}; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .hero-bg-square {
      width: 50%;
      padding-top: 50%;
      background: ${(props) => props.theme.palette.primary.main};
      background-size: contain;
      /* border: 1px solid green; */
      position: relative;

      img {
        margin-top: -100%;
        width: 100%;
        position: absolute;
        z-index: 2;
      }
    }

    .hero-image {
      position: absolute;
      --image-width: 110%;
      width: var(--image-width);
      height: calc(var(--image-width) * 1.5);
      left: 0;
      bottom: 0;
      background: url(${profilePic});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      z-index: 2;

      /* border: 1px solid cyan; */
    }

    .hero-headline {
      z-index: 1;
      width: 60%;
      margin-right: -10rem;
      border-radius: 12px;
      /* background: rgba(231, 228, 223, 0.15); */
      background: rgba(0, 0, 0, 0.5);
      padding: 3rem 3rem;

      h1 {
        white-space: nowrap;
        position: relative;
        line-height: 1.3;
        font-family: "Raleway";
        margin-right: 10rem;
        font-size: 2.4rem;
        font-weight: 500;
        color: ${(props) => props.theme.palette.primary.main};
        .span-nome {
          font-size: 4.5rem;
          font-weight: 900;
          color: ${(props) => props.theme.palette.primary.main};
          text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
        }
        &:after {
          position: absolute;
          content: "";
          height: 5px;
          margin: 0 auto;
          bottom: -8px;
          left: 0;
          right: 0;
          width: 150%;
          background: ${(props) => props.theme.palette.secondary.main};
        }
      }

      h2 {
        color: ${(props) => props.theme.palette.primary.main};
        font-size: 1.4rem;
        font-weight: 300;
        margin: 2rem 0 0 0;
      }

      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }

    @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
      flex-direction: column-reverse;
      width: 100%;
      margin: 0;
      margin-top: ${RatazimaTheme.dimensions.navBarHeight};

      .hero-bg-square {
        width: 65%;
      }

      .hero-headline {
        z-index: 1;
        width: 90%;
        margin-right: 0;
        border-radius: 12px;
        /* background: rgba(231, 228, 223, 0.15); */
        padding: 2rem;
        background: rgba(0, 0, 0, 0.35);
        -webkit-backdrop-filter: blur(2px);
        backdrop-filter: blur(2px);

        h1 {
          margin: 0;
          padding: 0;
          font-size: 2.2rem;

          .span-nome {
            font-size: calc(100vw / 8 * 1.1);
          }
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
`;
